const {
  REACT_APP_API_PROXY_URL,
  REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_OKTA_URL,
  NODE_ENV,
  REACT_APP_TERMS_LAST_UPDATE = '2001-01-01T00:00:00.000Z',

  // development environment variables
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_SAMPLE_RATE,
  REACT_APP_LOGIN_SPLASH_MESSAGE,
  REACT_APP_USE_CUSTOM_LOGIN,
  REACT_APP_ISSUER_ROOT,
  REACT_APP_URL_ROOT,
  REACT_APP_MAMMOTH_UI_URL,
  REACT_APP_FULLSTORY_ENV
} = process.env

const isDev = NODE_ENV === 'development'
const isProd = NODE_ENV === 'production'
const isTest = NODE_ENV === 'test'

const IGNORE_SUBDOMAINS = ['dev', 'www', 'preview']

export const getSubdomain = (override) => {
  if (override) return override
  if (window.location.hostname === 'localhost') return null
  const hostParts = window.location.hostname.split('.')
  if (hostParts.length <= 2) return null
  const subdomainValue = hostParts.at(0)
  if (IGNORE_SUBDOMAINS.includes(subdomainValue)) return null

  return subdomainValue
}

const subdomain = getSubdomain()
const oktaUrl = subdomain ? `https://${subdomain}.${REACT_APP_URL_ROOT}` : `https://${REACT_APP_URL_ROOT}`

// okta.myAccount.password.manage

const config = {
  env: {
    isDev,
    isProd,
    isTest
  },
  oktaAuthConfig: {
    authority: REACT_APP_OKTA_URL,
    issuer: `${REACT_APP_OKTA_URL}/oauth2/default`,
    clientId: REACT_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email', 'okta.myAccount.profile.read', 'okta.myAccount.authenticators.read']
  },
  sentry: {
    dsn: REACT_APP_SENTRY_DSN,
    envName: NODE_ENV,
    tracesSampleRate: REACT_APP_SENTRY_SAMPLE_RATE || '1.0'
  },
  apiProxyUrl: REACT_APP_API_PROXY_URL || '/api',
  loginSplashMessage: REACT_APP_LOGIN_SPLASH_MESSAGE,
  termsLastUpdateDate: REACT_APP_TERMS_LAST_UPDATE,
  useCustomLogin: REACT_APP_USE_CUSTOM_LOGIN === '1',
  issuerRoot: REACT_APP_ISSUER_ROOT,
  urlRoot: REACT_APP_URL_ROOT, // this is OKTA_URL_ROOT ...
  subdomain,
  oktaUrl,
  analytics: {
    env: REACT_APP_FULLSTORY_ENV
  },
  mammothUiUrl: REACT_APP_MAMMOTH_UI_URL || 'https://admin.swsapp.com'
}

export default config

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { USER_TYPE } from '../../../../../../../constants'
import SydInput from '../../../../../../commonDesign/SydInput'

export const PersonIdCell = ({ id, row, disabled, onChange, classes }) => {
  useEffect(() => {
    if (row.userTypeId?.toString() === USER_TYPE.ADVISOR?.toString() && row.personId?.toString()?.length) {
      onChange({ target: { value: '' } })
    }
  }, [row.userTypeId, onChange, row.allAccountAccess, row.personId])

  if (row.userTypeId?.toString() === USER_TYPE.ADVISOR?.toString()) {
    return <p className={classes.disabledDisplay}>--</p>
  }

  if (disabled) {
    return (
      <p className={classes.disabledDisplay}>{row.personId}</p>
    )
  }

  return (
    <>
      <SydInput
        minWidth='0px'
        type='text'
        value={row.personId}
        id={id}
        onChange={onChange}
        size='xs'
      />
    </>
  )
}

PersonIdCell.propTypes = {
  id: PropTypes.string,
  row: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  classes: PropTypes.object
}

import React from 'react'
import PropTypes from 'prop-types'
import { USER_TYPE } from '../../../../../../../constants'

export const UserTypeSelectCell = ({ id, row, disabled, onChange, classes }) => {
  if (disabled) {
    return (
      <p className={classes.disabledDisplay}>
        {row.userTypeId === USER_TYPE.WEALTH_OWNER ? 'Wealth Owner' : row.userTypeId === USER_TYPE.ADVISOR ? 'Advisor' : 'None Selected'}
      </p>
    )
  }

  return (
    <>
      <select id={id} onChange={onChange}>
        <option value=''>Select Type</option>
        <option
          key={USER_TYPE.WEALTH_OWNER}
          value={USER_TYPE.WEALTH_OWNER}
          selected={USER_TYPE.WEALTH_OWNER.toString() === row.userTypeId?.toString()}
        >
          Wealth Owner
        </option>
        <option
          key={USER_TYPE.ADVISOR}
          value={USER_TYPE.ADVISOR}
          selected={USER_TYPE.ADVISOR.toString() === row.userTypeId?.toString()}
        >
          Advisor
        </option>
      </select>
    </>
  )
}

UserTypeSelectCell.propTypes = {
  id: PropTypes.string,
  row: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  classes: PropTypes.object
}

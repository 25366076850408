import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  dropAreaDisplay: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export const DropAreaDisplay = ({ openFileUploadWindow, file }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.dropAreaDisplay}
      onClick={openFileUploadWindow}
    >
      {!file ? (
        <>
          Drag and drop a CSV file here<br />
          or<br />
          Click to browse your files
        </>
      ) : (
        <>
          {file.path}
        </>
      )}
    </div>
  )
}

DropAreaDisplay.propTypes = {
  openFileUploadWindow: PropTypes.func.isRequired,
  file: PropTypes.object
}

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useQueryClient } from '@tanstack/react-query'
import { useGetUserBatch } from '../../../../../../api/userBatches'
import { TEXT_VARIANTS } from '../../../../../../constants'
import Card from '../../../../../molecules/Card'
import SydLabel from '../../../../../commonDesign/SydLabel'
import Text from '../../../../../atoms/Text'
import SydButton from '../../../../../commonDesign/Button'
import { useRetryUserBatchMutation } from '../../../../../../api/users'
import { QUERY_KEYS } from '../../../../../../api/queryKeys'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import BatchTaskListTable from './BatchTaskListTable'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 50px)',
    overflow: 'hidden',
    gap: '1rem'
  },
  flexRow: {
    display: 'flex',
    width: '100%'
  },
  flexJustify: {
    justifyContent: 'space-between'
  },
  flexCol: {
    flexDirection: 'column'
  },
  flexGap: {
    gap: '16px'
  },
  statusTag: {
    border: '1px solid',
    borderRadius: '6px',
    display: 'inline-block',
    padding: '2px 6px',
    '&.errored': {
      backgroundColor: theme.palette.error.A50,
      color: theme.palette.error.main
    },
    '&.processing': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    '&.idle': {
      backgroundColor: theme.palette.gray.light,
      color: theme.palette.gray.dark
    },
    '&.complete': {
      backgroundColor: theme.palette.success.A50,
      color: theme.palette.success.main
    }
  }
}))

export const UserBatchDetails = ({
  userBatchId
}) => {
  const classes = useStyles()
  const { data: batch, isFetching } = useGetUserBatch(userBatchId)

  const { mutate, isLoading } = useRetryUserBatchMutation()
  const queryClient = useQueryClient()
  const { formatter } = useFormattingContext()

  const handleTriggerRefresh = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.userBatches], refetchType: 'all' }).catch(console.error)
  }, [queryClient])

  return (
    <>
      <div className={classes.container}>
        <Card>
          {isFetching ? <CircularProgress /> : (
            <>
              {batch && (
                <div className={clsx(classes.flexRow, classes.flexJustify, classes.flexGap)}>
                  <div className={clsx(classes.flexRow, classes.flexCol, classes.flexGap)}>
                    <SydLabel label='Batch Type'>
                      <Text variant={TEXT_VARIANTS.body1} text={batch.batchType} />
                    </SydLabel>

                    <SydLabel label='Status'>
                      <div className={clsx(classes.flexRow, classes.flexGap)}>
                        {batch.isError ? (
                          <div className={clsx(classes.statusTag, 'errored')}>Errored</div>
                        ) : batch.isProcessing ? (
                          <div className={clsx(classes.statusTag, 'processing')}>Processing</div>
                        ) : batch.finishedDate ? (
                          <div className={clsx(classes.statusTag, 'complete')}>Completed</div>
                        ) : (
                          <div className={clsx(classes.statusTag, 'idle')}>Not Started</div>
                        )}
                        {!batch.finishedDate && (
                          <SydButton size='sm' onClick={handleTriggerRefresh} icon='sync' processing={isFetching}>
                            Reload
                          </SydButton>
                        )}
                      </div>
                    </SydLabel>
                  </div>
                  <div>
                    {!!batch.isError && <SydButton onClick={() => mutate(userBatchId)} processing={isLoading} disabled={isLoading}>Retry batch</SydButton>}
                    {!!batch.finishedDate?.length && (
                      <SydLabel label='Finished Date'>
                        <Text variant={TEXT_VARIANTS.body1} text={formatter(batch.finishedDate, '&M/D/YY h:mm a')} />
                      </SydLabel>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </Card>

        <BatchTaskListTable userBatchId={userBatchId} batchType={batch?.batchType} />
      </div>
    </>
  )
}

UserBatchDetails.propTypes = {
  userBatchId: PropTypes.string
}

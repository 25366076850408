import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import SydSelect from '../../../../../../commonDesign/SydSelect'
import SydModal from '../../../../../../commonDesign/SydModal'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import SydButton from '../../../../../../commonDesign/Button'
import SydLabel from '../../../../../../commonDesign/SydLabel'
import { USER_TYPE } from '../../../../../../../constants'

export const UserTypeFillModal = ({ open, onClose, onSubmit: _onSubmit }) => {
  const form = useForm({
    defaultValues: {
      userTypeId: USER_TYPE.WEALTH_OWNER
    }
  })

  const onSubmit = (values) => {
    _onSubmit?.(values.userTypeId)
    onClose?.()
    form.reset()
  }

  return (
    <SydModal
      open={open}
      disablePortal={false}
      onClose={onClose}
      title='Fill Empty User Types'
      subtitle='Choose the user types to fill any empty user types with below'
    >
      <form {...form} onSubmit={form.handleSubmit(onSubmit)}>
        <Controller
          name='userTypeId'
          control={form.control}
          rules={{ required: 'User Type is required' }}
          render={({ field, fieldState }) => (
            <SydLabel label='User Type' required error={fieldState.error?.message}>
              <SydSelect
                size='sm'
                {...field}
                options={[
                  { value: USER_TYPE.WEALTH_OWNER, label: 'Wealth Owner' },
                  { value: USER_TYPE.ADVISOR, label: 'Advisor' }
                ]}
              />
            </SydLabel>
          )}
        />
        <SydModalActions style={{ marginTop: '2rem' }}>
          <SydButton onClick={onClose} variant='secondary'>Cancel</SydButton>
          <SydButton onClick={form.handleSubmit(onSubmit)} variant='primary'>Fill Empty User Types</SydButton>
        </SydModalActions>
      </form>
    </SydModal>
  )
}

UserTypeFillModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

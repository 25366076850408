import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import SydButton from '../../../../../../commonDesign/Button'

const fillEmptyCellTriggerLabelMap = {
  roleId: 'Fill Empty Roles',
  userTypeId: 'Fill Empty User Types'
}

const headerLabelsMap = {
  roleId: 'Role',
  userTypeId: 'User Type',
  allAccountAccess: 'All Account Access',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  personId: 'Person ID',
  userId: 'User ID'
}

export const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'space-between',
    flexWrap: 'wrap-reverse'
  }
}))

export const DataPreviewTableHeaderCell = ({
  accessorKey,
  editing,
  emptyAccessors,
  onClick
}) => {
  const classes = useStyles()
  const label = headerLabelsMap[accessorKey] ?? accessorKey

  const showFillButton = editing && emptyAccessors.includes(accessorKey) && !!fillEmptyCellTriggerLabelMap[accessorKey]

  return (
    <span className={classes.flexContainer}>
      <span>{label}</span>
      {showFillButton && (
        <SydButton
          size='xs'
          icon='listAlt'
          variant='primary'
          onClick={() => onClick?.(accessorKey)}
        >
          {fillEmptyCellTriggerLabelMap[accessorKey]}
        </SydButton>
      )}
    </span>
  )
}

DataPreviewTableHeaderCell.propTypes = {
  accessorKey: PropTypes.string,
  editing: PropTypes.bool,
  emptyAccessors: PropTypes.array,
  onClick: PropTypes.func
}

import KeyMirror from 'keymirror'

export const QUERY_KEYS = KeyMirror({
  abundanceEngineViews: null,
  abundanceEngineViewContentById: null,
  accountTearSheet: null,
  accountBillingInfo: null,
  accountSearch: null,
  accountSearchMultiple: null,
  accountStatusDetailsSearch: null,
  accounts: null,
  advisorViewTemplates: null,
  allAdvisors: null,
  allocationHistory: null,
  alternatives: null,
  assetClassificationTagTypes: null,
  assetHoldings: null,
  assetTearSheet: null,
  assetTearSheetById: null,
  assetTearSheetList: null,
  assetTypes: null,
  billingRuns: null,
  billingRunDetails: null,
  bobiCheckExistingQuery: null,
  bobiRunOfflineQuery: null,
  bobiQueryOutput: null,
  bobiQueryOutputCount: null,
  bobiOutputStatus: null,
  bobiOutputList: null,
  bobiExportCsv: null,
  bobiSavedRequests: null,
  bookmarks: null,
  bookmarksMultiple: null,
  classificationTagTypes: null,
  classificationTags: null,
  clientBalances: null,
  teamMembers: null,
  clientTeamMembers: null,
  recentClientBalances: null,
  componentsOfChangeQuery: null,
  clientNetWorth: null,
  coreDataDataset: null,
  coreDataDatasetBuffered: null,
  coreSeriesData: null,
  currentUser: null,
  custodians: null,
  vaultDocuments: null,
  feeCalcTypes: null,
  feeMethods: null,
  feeSchedule: null,
  feeScheduleMultipleExposure: null,
  feeScheduleNoExposure: null,
  getAssociatedClients: null,
  getClient: null,
  getClientBillingInfo: null,
  getClients: null,
  getCoreTableDataQuery: null,
  getCurrentClient: null,
  getGroupedCoreData: null,
  getManagerById: null,
  getMedia: null,
  getUser: null,
  getUsers: null,
  getWealthOwnerStatusSummary: null,
  getWealthOwnerStatusDetails: null,
  getAdvisorStatusSummary: null,
  getAdvisorStatusDetails: null,
  getBenchmarkReturns: null,
  infiniteSearchClients: null,
  ingestionBatchById: null,
  ingestionBatchStatus: null,
  ingestionGetPlatformDataSourceById: null,
  ingestionPublishingPolicySearch: null,
  ingestionObjectMappingById: null,
  ingestionJobById: null,
  ingestionJobByIdWithPublishing: null,
  ingestionListJobs: null,
  ingestionListPlatformDataSources: null,
  ingestionTargetById: null,
  ingestionQueryPlatformDataSource: null,
  ingestionCountCreatingItems: null,
  ingestionPreviewCreatingItems: null,
  ingestionCountUpdatingItems: null,
  ingestionPreviewUpdatingItems: null,
  ingestionPublishingRunById: null,
  ingestionSearchTargets: null,
  latestClientStatusReport: null,
  getStatusTemplateItemOptions: null,
  getIntegrationSourceByKey: null,
  leadAdvisors: null,
  levelDates: null,
  listAssignedClientAccounts: null,
  listAssignedClientPeople: null,
  listAssignedWealthOwnerClients: null,
  listAssignedAdvisorClients: null,
  listEnablementCodes: null,
  listIntegrationDomains: null,
  listIntegrations: null,
  listIntegrationSources: null,
  listPersonTypes: null,
  listTaxStatusOptions: null,
  normalizeDates: null,
  normalizeRelativeDates: null,
  projectedIncome: null,
  policy: null,
  policies: null,
  recentClients: null,
  recentlyViewedClients: null,
  redtailSyncStatus: null,
  reportTemplates: null,
  reportRuntimeConfigurations: null,
  reports: null,
  reportsBatches: null,
  roleTypes: null,
  searchAccounts: null,
  searchAccountsMultiple: null,
  searchAssetExposure: null,
  searchAssets: null,
  searchClients: null,
  searchClientsMultiple: null,
  searchGainLoss: null,
  searchGroupTypes: null,
  searchGroups: null,
  searchAllPeople: null,
  searchAllPeopleMultiple: null,
  searchAssignedPeople: null,
  searchAssignedPeopleMultiple: null,
  searchIntegrationJobs: null,
  searchIntegrationSources: null,
  searchIntegrationBatches: null,
  searchManagers: null,
  searchPositions: null,
  searchStatusTemplates: null,
  searchTransactionCodeTags: null,
  searchTransactions: null,
  searchKeyDatesMultiple: null,
  searchUsers: null,
  searchWealthOwners: null,
  searchAdvisorUsers: null,
  getAdvisorTeamMember: null,
  getIntegrationBatchById: null,
  getTeamMemberSchema: null,
  getUserById: null,
  getUserByExternalId: null,
  getThingSchema: null,
  searchRoles: null,
  searchRole: null,
  searchPermissions: null,
  statusTemplate: null,
  listClientStatusTemplateAssignments: null,
  listAccountKeyDatapoints: null,
  listClientKeyDatapoints: null,
  listClientAccountGroupSchema: null,
  listManualPositionBatches: null,
  myAdvisor: null,
  getManualPositionBatchDetails: null,
  getClientAccountGroupHierarchy: null,
  getLevelDatapointSchema: null,
  getLevelDatapointValues: null,
  getAccountDatapointSchema: null,
  getAccountDatapointValues: null,
  getClientDatapointSchema: null,
  getClientDatapointValues: null,
  getClientStatusTemplateAssignment: null,
  tags: null,
  wealthboxUnlinkedTags: null,
  visualBalanceSheet: null,
  getBenchmarks: null,
  getSourceBenchmarks: null,
  getBenchmarksValues: null,
  billingSystemConfig: null,
  wealthMissionValues: null,
  wealthMissionValueDefaults: null,
  planning: null,
  exports: null,
  teamMember: null,
  userViews: null,
  roleViews: null,
  taggedViews: null,
  getEditableAccounts: null,
  getAccountStatuses: null,
  schwabRealtime: null,
  queryIntegrationTable: null,
  getIntegrationTableMetadata: null,
  userBatches: null
})

import React from 'react'
import PropTypes from 'prop-types'
import { RoleIdFillModal } from './RoleIdFillModal'
import { UserTypeFillModal } from './UserTypeFillModal'

export const fillCellModalMap = {
  roleId: RoleIdFillModal,
  userTypeId: UserTypeFillModal
}

export const FillCellModal = ({
  accessorKey, onClose, onSubmit
}) => {
  const ModalComponent = fillCellModalMap[accessorKey]

  if (!ModalComponent) {
    return null
  }

  return (
    <>
      <ModalComponent open onClose={onClose} onSubmit={(val) => onSubmit(accessorKey, val)} />
    </>
  )
}

FillCellModal.propTypes = {
  accessorKey: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

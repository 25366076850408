/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { ADMIN_ROUTES } from '../../../../constants'
import { useListUserTaskBatches } from '../../../../api/userBatches'
import OperationalTable, { useOperationalTable } from '../../../organisms/OperationalTable'
import { useFormattingContext } from '../../../organisms/FormattingProvider/FormattingContext'
import Icon from '../../../atoms/Icon'

const useStyles = makeStyles(() => ({
  batchTable: {
    marginTop: '10px',
    '& .__status': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '4px'
    }
  }
}))

const LinkCell = ({ value }) => {
  return <Link onClick={(e) => { e?.stopPropagation() }} to={`${ADMIN_ROUTES.USER_BATCHES}/batch/${value}`}>{value}</Link>
}

const StatusCell = ({ row }) => row?.original?.isError ? (
  <div className='__status'><Icon name='close' color='red' customSize={16} /> Error</div>
) : row?.original?.finishedDate ? (
  <div className='__status'><Icon name='check' color='green' customSize={16} /> Completed</div>
) : row?.original?.isPaused ? (
  <div className='__status'><Icon name='hand' color='yellow' customSize={16} /> Waiting on User Action</div>
) : (
  <div className='__status'><Icon name='noInfo' color='gray' customSize={16} /> Not Started</div>
)

const DateCell = ({ value }) => {
  const { formatter } = useFormattingContext()
  return <div>{formatter(value, '&M/D/YY h:mm a')}</div>
}

const NumberCell = ({ value }) => {
  const { formatter } = useFormattingContext()
  return <div>{formatter(value, '0,0')}</div>
}

const ProgressCell = ({ row }) => {
  return (
    <div>
      {row.original.taskSummary.completeCount} / {row.original.taskSummary.taskCount} Complete
      {row.original.taskSummary.errorCount > 0 && ` (${row.original.taskSummary.errorCount} Errors)`}
    </div>
  )
}

const columns = [
  { Header: 'Id', accessor: 'userBatchId', type: 'number', Cell: LinkCell },
  { Header: 'Status', id: 'status', accessor: 'isComplete', type: 'string', Cell: StatusCell },
  { Header: 'Progress', accessor: 'completedTasks', type: 'number', alignRight: true, Cell: ProgressCell },
  { Header: 'Errored', accessor: 'erroredTasks', type: 'number', alignRight: true },
  { Header: 'Records', accessor: 'recordCount', type: 'number', alignRight: true, Cell: NumberCell },
  { Header: 'Created', accessor: 'createdDate', type: 'string', Cell: DateCell },
  { Header: 'Ran By', accessor: 'createdBy', type: 'string' }
]
const defaultSort = [{ id: 'userBatchId', desc: true }]
const defaultPageSize = 50

function BatchListTable ({ batchType }) {
  const classes = useStyles()

  const {
    pageSize,
    pageIndex,
    sort
  } = useOperationalTable({
    defaultPageSize,
    defaultSort
  })

  const history = useHistory()
  const onClick = useCallback((row) => {
    if (row.original) {
      history.push(`${ADMIN_ROUTES.USER_BATCHES}/batch/${row.original.userBatchId}`)
    }
  }, [history])

  const { data, isFetching } = useListUserTaskBatches({
    filters: { batchType: batchType },
    includes: { taskSummary: true },
    take: pageSize,
    skip: pageIndex * pageSize,
    sort: sort.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))
  }, {
    enabled: !!batchType
  })

  return (
    <OperationalTable.Wrapper className={classes.batchTable}>
      <OperationalTable
        data={data || []}
        variant='v2'
        loading={isFetching}
        defaultPageSize={defaultPageSize}
        columns={columns}
        onRowClick={onClick}
        defaultSort={defaultSort}
        mode='server'
      />
    </OperationalTable.Wrapper>
  )
}

BatchListTable.propTypes = {
  batchType: PropTypes.string
}

export default BatchListTable

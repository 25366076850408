import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  return {
    instructions: {
      fontWeight: 'lighter',
      backgroundColor: theme.palette.gray.light,
      borderRadius: '8px',
      padding: '0 1rem',
      border: `1px solid ${theme.palette.gray.darker}`,
      '& strong': {
        fontWeight: 400,
        fontFamily: theme.typography.families.bold
      }
    }
  }
})

const instructionsMap = {
  'bulk-create-users': (
    <>
      <p>
        <strong>Instructions</strong>
      </p>
      <ul>
        <li>Accepted file type: <strong>.csv</strong></li>
        <li>First row must be column names</li>
        <li>Required
          columns: <strong>personId</strong>, <strong>clientId</strong>, <strong>firstName</strong>, <strong>lastName</strong>, <strong>email</strong>, <strong>userTypeId</strong>, <strong>roleId</strong>
        </li>
        <li>Optional columns: <strong>allAccountAccess (defaults to false)</strong></li>
        <li>All other columns will not be used for this job</li>
      </ul>
    </>
  )
}

export const CreateBatchInstructions = ({ batchType }) => {
  const classes = useStyles()
  if (!batchType || !instructionsMap[batchType]) return null

  return (
    <div className={classes.instructions}>
      {instructionsMap[batchType]}
    </div>
  )
}

CreateBatchInstructions.propTypes = {
  batchType: PropTypes.string.isRequired
}

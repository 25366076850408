import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { FillCellModal } from '../fillCellModal'
import { DataPreviewTableHeaderCell } from './DataPreviewTableHeaderCell'

const useStyles = makeStyles(theme => ({
  rowHeader: {
    backgroundColor: theme.palette.gray.light,
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.gray.darker}`,
    textAlign: 'left',
    color: 'rgba(0,0,0,0.5)',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    lineHeight: '19px',
    minWidth: 'fit-content',
    '&.required': {
      fontWeight: '400',
      fontFamily: theme.typography.families.bold,
      color: 'rgba(0,0,0,1)'
    }
  }
}))

export const DataPreviewTableHeader = ({
  columns,
  editing,
  emptyAccessors,
  usedColumns,
  onFillSubmit
}) => {
  const classes = useStyles()
  const [fillModalKey, setFillModalKey] = useState(null)

  return (
    <>
      <thead>
        <tr>
          <th className={classes.rowHeader}>Row</th>
          <th className={classes.rowHeader}>Status</th>
          {columns.map(cn => (
            <th
              key={cn}
              className={clsx(classes.rowHeader, { required: usedColumns?.includes(cn) })}
            >
              <DataPreviewTableHeaderCell
                accessorKey={cn}
                editing={editing}
                emptyAccessors={emptyAccessors}
                onClick={(accKey) => setFillModalKey(accKey)}
              />
            </th>
          ))}
        </tr>
      </thead>

      {fillModalKey && (
        <FillCellModal
          accessorKey={fillModalKey}
          onClose={() => setFillModalKey(null)}
          onSubmit={onFillSubmit}
        />
      )}
    </>
  )
}

DataPreviewTableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  editing: PropTypes.bool,
  emptyAccessors: PropTypes.array,
  usedColumns: PropTypes.array,
  onFillSubmit: PropTypes.func
}

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import SydLinkButton from '../../../commonDesign/LinkButton'
import { ADMIN_ROUTES } from '../../../../constants'
import SydTabs from '../../../commonDesign/SydTabs'
import BatchListTable from './BatchListTable'

const useStyles = makeStyles({
  container: {
    padding: '1rem'
  },
  headerContainer: {}
})

const tabs = {
  'bulk-create-users': {
    label: 'Bulk Create Users'
  },
  'batch-invite-users': {
    label: 'Bulk Invite Users'
  }
}

export const UserBatchesAdmin = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(tabs?.[0]?.value ?? null)

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <SydTabs.Group
          tabOptions={tabs}
          onChange={(val) => setCurrentTab(val)}
          defaultValue={currentTab}
          key='user_batches_admin'
        >
          <SydTabs.Links
            rightAdornment={(
              <header className={classes.header}>
                <SydLinkButton
                  size='md'
                  variant='primary'
                  icon='add'
                  to={`${ADMIN_ROUTES.USER_BATCHES}/batch/new/?taskType=${currentTab}`}
                >
                  New User Batch
                </SydLinkButton>
              </header>
            )}
          />
        </SydTabs.Group>
      </div>

      <BatchListTable batchType={currentTab} />
    </div>
  )
}

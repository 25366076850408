import { useQuery } from '@tanstack/react-query'
import { postNamedQuery } from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useListUserTaskBatches = (body = {}, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper, refetchInterval } = options
  return useQuery({
    queryKey: [QUERY_KEYS.userBatches, userId, body],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'list-user-batches', body ?? {})
      return data
    },
    refetchInterval,
    select: mapper,
    enabled
  })
}

export const useGetUserBatch = (userBatchId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper, refetchInterval } = options
  return useQuery({
    queryKey: [QUERY_KEYS.userBatches, userId, userBatchId],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'get-user-batch', { userBatchId })
      return data
    },
    refetchInterval,
    select: mapper,
    enabled
  })
}

export const useGetUserBatchTasks = (body, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper, refetchInterval } = options
  return useQuery({
    queryKey: [QUERY_KEYS.userBatches, 'tasks', userId, body],
    queryFn: async () => {
      const { data } = await postNamedQuery('users', 'list-user-batch-tasks', body || {})
      return data
    },
    refetchInterval,
    select: mapper,
    enabled
  })
}

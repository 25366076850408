import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { USER_TYPE } from '../../../../../../../constants'
import Icon from '../../../../../../atoms/Icon'

export const AllAccountAccessCell = ({ id, row, disabled, onChange, classes }) => {
  useEffect(() => {
    if (row.userTypeId !== USER_TYPE.ADVISOR?.toString() && row.allAccountAccess?.toString() === 'true') {
      onChange({ target: { value: 'false' } })
    }
    if (!row.allAccountAccess?.toString().length) {
      onChange({ target: { value: 'false' } })
    }
  }, [row.userTypeId, onChange, row.allAccountAccess])

  if (row.userTypeId?.toString() !== USER_TYPE.ADVISOR?.toString()) {
    return <p className={classes.disabledDisplay}>--</p>
  }

  if (disabled) {
    return (
      <p className={classes.disabledDisplay}>
        {row.allAccountAccess?.toString() === 'true' ? <Icon name='check' color='green' /> : <Icon name='close' color='red' />}
      </p>
    )
  }

  return (
    <>
      <input
        type='checkbox'
        value='true'
        id={id}
        checked={row.allAccountAccess?.toString() === 'true'}
        onChange={(e) => {
          onChange({ target: { value: e.target.checked } })
        }}
      />
    </>
  )
}

AllAccountAccessCell.propTypes = {
  id: PropTypes.string,
  row: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  classes: PropTypes.object
}

import { useCallback } from 'react'

export const useGetRowMissingFields = (requiredColumns) => (
  useCallback((row) => {
    return requiredColumns.filter(c => {
      if (typeof c === 'object') {
        const matchesReqs = Object.keys(c).every(k => {
          if (typeof k === 'boolean') {
            return !row[k]?.toString()?.length
          }

          return row[k]?.toString() !== c[k]?.toString()
        })

        return !matchesReqs
      }

      return !row[c]?.toString()?.length
    })
  }, [requiredColumns])
)

import WealthOwnerDetails from './components/pages/admin/WealthOwnerDetails'
import AdvisorsView from './components/pages/admin/Advisors'
import AdvisorView from './components/pages/admin/Advisor'
import RolesView from './components/pages/admin/Roles/RolesView'
import RoleDetails from './components/pages/admin/Roles/RoleDetails'
import ClientsView from './components/pages/admin/Clients'
import PersonsView from './components/pages/admin/PersonsView'
import AdminHomeView from './components/pages/admin/AdminHomeView'
import ClientDetailsView2 from './components/pages/admin/ClientDetails'
import NewClientForm from './components/pages/admin/ClientDetails/NewClient'
import ClassifyAssets from './components/pages/admin/ClassifyAssets'
import AbundanceEngineView from './components/pages/admin/abundanceEngineView'
import BillingView from './components/pages/admin/BillingView'
import FeeSchedules from './components/pages/admin/billing/FeeSchedules'
import NoFeeScheduleAccounts from './components/pages/admin/billing/NoFeeScheduleAccounts'
import MultipleFeeScheduleAccounts from './components/pages/admin/billing/MultipleFeeScheduleAccounts'
import FeeScheduleDetails from './components/pages/admin/billing/FeeScheduleDetails'
import ClientFeeAssignments from './components/pages/admin/billing/ClientFeeAssignments'
import RunBilling from './components/pages/admin/billing/runBilling'
import BillingClientManagementIndex from './components/pages/admin/billing/clientReview'
import PreferencesView from './components/pages/preferences'
import { ADMIN_ROUTES, DATA_HOME_ROUTES, FEATURE_FLAG, PASSWORD_ROUTES } from './constants'
import AccountDetailsView from './components/pages/admin/AccountDetails'
import IntegrationsView from './components/pages/admin/Integrations/IntegrationsView'
import ReportsView from './components/pages/admin/Reports'
import ManageIntegration from './components/pages/admin/Integrations/ManageIntegration'
import ReportTemplates from './components/pages/admin/Reports/Templates'
import ReportTemplateConfigurations from './components/pages/admin/Reports/TemplateConfiguration'
import DataHomeIndex from './components/pages/dataHome'
import AssetDetailsView from './components/pages/admin/AssetDetails'
import BatchRunsView from './components/pages/admin/Reports/Batches'
import BatchRunDetailView from './components/pages/admin/Reports/Batches/BatchDetail'
import BlendedForm from './components/pages/admin/Benchmarks/Blended'
import SourceInfo from './components/pages/admin/Benchmarks/Source'
import Benchmarks from './components/pages/admin/Benchmarks'
import RebalancerIndex from './components/pages/rebalancer'
import StatusReportsIndex from './components/pages/admin/StatusReports'
import ModelAdminIndex from './components/pages/admin/Models'
import TargetModelDetails from './components/pages/admin/Models/TargetModelDetails'
import {
  ADMIN,
  ADVISOR,
  ASSETS,
  BENCHMARKS,
  BILLING,
  DATA_HOME,
  INTEGRATIONS,
  REPORTS,
  ROLES,
  USER_BATCHES
} from './policies/admin'
import ConversationsIndex from './components/pages/conversations'
import AccountsView from './components/pages/admin/Accounts'
import AcceptTerms from './components/pages/accept-terms'
import FirmReporting from './components/pages/firmReporting'
import AdvisorRoleNew from './components/pages/admin/Roles/RoleNew/AdvisorRoleNew'
import WealthOwnerRoleNew from './components/pages/admin/Roles/RoleNew/WealthOwnerRoleNew'
import CustomIconsView from './components/pages/CustomIconsView'
import LoginContainer from './components/pages/publicViews/LoginContainer'
import StatusTemplateDetails from './components/pages/admin/StatusTemplateDetails'
import NewStatusTemplate from './components/pages/admin/StatusTemplateDetails/NewStatusTemplate'
import ManagerDetailsIndex from './components/pages/admin/ManagerDetails'
import NewManager from './components/pages/admin/ManagerDetails/NewManager'
import ManualAccountsView from './components/pages/admin/Accounts/Manual'
import ManualAccountsNewBatchView from './components/pages/admin/Accounts/Manual/NewBatch'
import ManualAccountsBatchDetailView from './components/pages/admin/Accounts/Manual/BatchDetail'
import ComponentModelDetails from './components/pages/admin/Models/ComponentModelDetails'
import NewComponentModel from './components/pages/admin/Models/ComponentModelDetails/NewComponentModel'
import NewTargetModel from './components/pages/admin/Models/TargetModelDetails/NewTargetModel'
import DenaliLabsIndex from './components/pages/denaliLabs'
import DeactivatedUsers from './components/pages/admin/Clients/DeactivatedUsers'
import NewAdvisor from './components/pages/admin/Advisor/NewAdvisor'
import BillingRunDetailsIndex from './components/pages/admin/billing/billingRunDetails'
import DataMaintenance from './components/pages/admin/dataMaintenance'
import TransactionTaggingIndex from './components/pages/admin/dataMaintenance/transactionTagging'
import MediaIndex from './components/pages/admin/media'
import VaultAdmin from './components/pages/admin/Vault'
import DenaliIndex from './components/pages/denali'
import IntegratorHome from './components/pages/denali/Integrator/IntegratorHome'
import ReportingView from './components/pages/admin/ReportingView'
import InfographicReportsView from './components/pages/admin/InfographicReports'
import EditPipeline from './components/pages/denali/Analysis/Pipelines/EditPipeline/EditPipelineHome'
import EventLogsHome from './components/pages/denali/Catalog/EventLogs/EventLogsHome'
import CatalogHome from './components/pages/denali/Catalog/CatalogHome'
import DataSourceList from './components/pages/denali/Catalog/DataSourceList/DataSourceList'
import DataSourceDetails from './components/pages/denali/Catalog/DataSourceDetails'
import PipelinesHome from './components/pages/denali/Analysis/Pipelines/PipelinesHome'
import InfographicTemplatesListView from './components/pages/admin/InfographicReports/InfographicTemplatesListView'
import InfographicTemplatesDetailsView
  from './components/pages/admin/InfographicReports/InfographicTemplatesDetailsView'
import DeactivatedAdvisors from './components/pages/admin/Advisors/DeactivatedAdvisors'
import { UserBatchesAdmin } from './components/pages/admin/UserBatches'
import { UserBatch } from './components/pages/admin/UserBatches/UserBatch'

export const staticViews = [
  {
    Component: AbundanceEngineView,
    path: ADMIN_ROUTES.ABUNDANCE_ENGINE_VIEWS,
    name: 'Abundance Views',
    exact: true,
    forAdvisor: true,
    backButton: true
  },
  {
    Component: AcceptTerms,
    path: '/accept-terms',
    name: 'Accept Terms & Conditions',
    exact: true,
    forAdvisor: true,
    backButton: false,
    public: true // prevemt access tp sidebar/app content
  },
  {
    Component: DeactivatedUsers,
    path: `${ADMIN_ROUTES.WEALTH_OWNERS}/deactivated`,
    name: 'Deactivated Wealth Owners',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: `${ADMIN_ROUTES.CLIENTS}?tab=users`,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: ['admin_edit_wo_users']
  },
  {
    Component: WealthOwnerDetails,
    path: `${ADMIN_ROUTES.WEALTH_OWNERS}/:externalUserId`,
    name: 'Wealth Owners',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: `${ADMIN_ROUTES.CLIENTS}?tab=users`
  },
  {
    Component: AdvisorsView,
    path: ADMIN_ROUTES.ADVISORS,
    name: 'Advisors',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: [ADVISOR.viewAdvisor]
  },
  {
    Component: DeactivatedAdvisors,
    path: `${ADMIN_ROUTES.ADVISORS}/deactivated`,
    name: 'Deactivated Advisors',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: `${ADMIN_ROUTES.ADVISORS}`,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: ['admin_edit_advisor_users']
  },
  {
    Component: AdvisorView,
    path: `${ADMIN_ROUTES.ADVISORS}/:externalUserId`,
    name: 'Advisor',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: [ADVISOR.viewAdvisor]
  },
  {
    Component: NewAdvisor,
    path: ADMIN_ROUTES.ADVISORS_NEW,
    name: 'Add an Advisor',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: ADMIN_ROUTES.ADVISORS,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: [ADVISOR.editAdvisor]
  },
  {
    Component: RolesView,
    path: ADMIN_ROUTES.ROLES,
    name: 'Roles',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: [ROLES.viewAdvisorRoles]
  },
  {
    Component: AdvisorRoleNew,
    path: ADMIN_ROUTES.ROLE_NEW,
    name: 'New Role',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: [ROLES.editAdvisorRoles]
  },
  {
    Component: WealthOwnerRoleNew,
    path: `${ADMIN_ROUTES.WEALTH_OWNERS}/roles/new`,
    name: 'New Experience',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: `${ADMIN_ROUTES.CLIENTS}?tab=experiences`,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: [ROLES.editWealthOwnerRoles]
  },
  {
    Component: RoleDetails,
    path: `${ADMIN_ROUTES.ROLES}/:roleId`,
    name: 'Roles',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    policies: [ROLES.viewAdvisorRoles]
  },
  {
    Component: RoleDetails,
    path: `${ADMIN_ROUTES.WEALTH_OWNERS}/roles/:roleId`,
    name: 'Roles',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.ADVISOR_AND_ROLE_SETUP,
    parentPath: `${ADMIN_ROUTES.CLIENTS}?tab=experiences`,
    policies: [ROLES.viewAdvisorRoles]
  },
  {
    Component: ClientsView,
    path: ADMIN_ROUTES.CLIENTS,
    name: 'Clients',
    exact: true,
    forAdvisor: true,
    backButton: true
  },
  {
    Component: NewClientForm,
    path: `${ADMIN_ROUTES.CLIENTS}/new`,
    name: 'Create Client',
    exact: true,
    forAdvisor: true,
    backButton: true
  },
  {
    Component: ClientDetailsView2,
    path: `${ADMIN_ROUTES.CLIENTS}/:id`,
    name: 'Client Details',
    exact: true,
    forAdvisor: true,
    backButton: true
  },
  {
    Component: UserBatchesAdmin,
    path: ADMIN_ROUTES.USER_BATCHES,
    name: 'User Management',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [USER_BATCHES.viewUserBatches]
  },
  {
    Component: UserBatch,
    path: `${ADMIN_ROUTES.USER_BATCHES}/batch/:userBatchId`,
    name: 'User Task Batch Details',
    parentPath: ADMIN_ROUTES.USER_BATCHES,
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [USER_BATCHES.viewUserBatches]
  },
  {
    Component: AccountsView,
    path: ADMIN_ROUTES.ACCOUNTS,
    name: 'Accounts',
    exact: true,
    forAdvisor: true,
    backButton: true
  },
  {
    Component: ManualAccountsView,
    path: '/admin/manual-accounts',
    name: 'Manual Accounts',
    forAdvisor: true,
    exact: true,
    backButton: true,
    parentPath: '/admin/accounts'
  },
  {
    Component: ManualAccountsNewBatchView,
    path: '/admin/manual-accounts/batch/new',
    name: 'Upload Manual Accounts',
    forAdvisor: true,
    exact: true,
    backButton: true,
    parentPath: '/admin/manual-accounts'
  },
  {
    Component: ManualAccountsBatchDetailView,
    path: '/admin/manual-accounts/batch/:batchId',
    name: 'Manual Account Batch',
    forAdvisor: true,
    exact: true,
    backButton: true,
    parentPath: '/admin/manual-accounts'
  },
  {
    Component: ReportingView,
    path: ADMIN_ROUTES.REPORTING,
    name: 'Reports',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS
  },
  {
    Component: ReportsView,
    path: ADMIN_ROUTES.REPORTS,
    name: 'Reports',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS,
    policies: [REPORTS.runReports],
    parentPath: ADMIN_ROUTES.REPORTING
  },
  {
    Component: BatchRunsView,
    path: ADMIN_ROUTES.REPORTS_BATCHES,
    name: 'Report Batches',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS,
    policies: [REPORTS.runReports]
  },
  {
    Component: BatchRunDetailView,
    path: ADMIN_ROUTES.REPORTS_BATCH,
    name: 'Report Batches',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS,
    policies: [REPORTS.runReports]
  },
  {
    Component: InfographicReportsView,
    path: ADMIN_ROUTES.INFOGRAPHIC_REPORTS,
    name: 'Infographic Report Runs',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS,
    policies: [REPORTS.runInfographicReports]
  },
  {
    Component: InfographicTemplatesListView,
    path: ADMIN_ROUTES.INFOGRAPHIC_REPORTS_TEMPLATE,
    name: 'Infographic Templates',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS,
    parentPath: ADMIN_ROUTES.REPORTING,
    policies: [REPORTS.runInfographicReports]
  },
  {
    Component: InfographicTemplatesDetailsView,
    path: `${ADMIN_ROUTES.INFOGRAPHIC_REPORTS_TEMPLATE}/:templateId`,
    name: 'Infographic Template Details',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS,
    parentPath: ADMIN_ROUTES.INFOGRAPHIC_REPORTS_TEMPLATE,
    policies: [REPORTS.runInfographicReports]
  },
  {
    Component: AccountDetailsView,
    path: `${ADMIN_ROUTES.ACCOUNTS}/:id`,
    name: 'Account Details',
    exact: true,
    forAdvisor: true,
    backButton: true
  },
  {
    Component: PersonsView,
    path: ADMIN_ROUTES.PERSONS,
    name: 'Persons',
    exact: true,
    forAdvisor: true,
    backButton: true
  },
  {
    Component: AdminHomeView,
    path: ADMIN_ROUTES.ADMIN,
    name: 'Admin',
    exact: true,
    forAdvisor: true,
    policies: [ADMIN.viewAdmin]
  },
  {
    Component: ClassifyAssets,
    path: ADMIN_ROUTES.CLASSIFY_ASSETS,
    name: 'Assets',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.CLASSIFIED_ASSETS_SETUP,
    policies: [ASSETS.viewAssets]
  },
  {
    Component: AssetDetailsView,
    path: `${ADMIN_ROUTES.CLASSIFY_ASSETS}/:id`,
    name: 'Asset Details',
    exact: true,
    backButton: true,
    forAdvisor: true,
    policies: [ASSETS.viewAssets]
  },
  {
    Component: LoginContainer,
    path: PASSWORD_ROUTES.FORGOT,
    exact: true,
    forAdvisor: false,
    public: true
  },
  {
    Component: LoginContainer,
    path: PASSWORD_ROUTES.ACTIVATION,
    exact: true,
    forAdvisor: false,
    public: true
  },
  {
    Component: LoginContainer,
    path: PASSWORD_ROUTES.RECOVER,
    exact: true,
    forAdvisor: false,
    public: true
  },
  {
    Component: LoginContainer,
    path: PASSWORD_ROUTES.RESET_USER,
    exact: true,
    forAdvisor: false,
    public: true
  },
  {
    Component: DataMaintenance,
    path: ADMIN_ROUTES.DATA_MAINTENANCE,
    name: 'Data Maintenance',
    exact: true,
    forAdvisor: true,
    backButton: true,
    public: false,
    parentPath: ADMIN_ROUTES.ADMIN
  },
  {
    Component: TransactionTaggingIndex,
    path: ADMIN_ROUTES.TRANSACTION_TAGGING,
    name: 'Transaction Tagging',
    exact: true,
    forAdvisor: true,
    backButton: true,
    public: false,
    parentPath: ADMIN_ROUTES.DATA_MAINTENANCE
  },
  {
    Component: BillingView,
    path: ADMIN_ROUTES.BILLING,
    name: 'Billing',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [BILLING.viewFeeSchedules, BILLING.runBilling],
    policyOperator: 'or'
  },
  {
    Component: IntegrationsView,
    path: ADMIN_ROUTES.INTEGRATIONS,
    name: 'Integrations',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [INTEGRATIONS.viewIntegrations]
  },
  {
    Component: IntegrationsView,
    path: ADMIN_ROUTES.INTEGRATION,
    name: 'Integrations',
    exact: true,
    forAdvisor: true,
    policies: [INTEGRATIONS.viewIntegrations]
  },
  {
    Component: ManageIntegration,
    path: ADMIN_ROUTES.MANAGE_INTEGRATION,
    name: 'Manage',
    exact: true,
    forAdvisor: true,
    policies: [INTEGRATIONS.editIntegrations]
  },
  {
    Component: BillingClientManagementIndex,
    path: ADMIN_ROUTES.BILLING_CLIENT_REVIEW,
    name: 'Client Review',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: ADMIN_ROUTES.BILLING
  },
  {
    Component: FeeSchedules,
    path: ADMIN_ROUTES.BILLING_FEE_SCHEDULES,
    name: 'Fee Schedules',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [BILLING.viewFeeSchedules]
  },
  {
    Component: FeeScheduleDetails,
    path: ADMIN_ROUTES.BILLING_FEE_SCHEDULES_NEW,
    name: 'Add Fee Schedule',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [BILLING.editFeeSchedules]
  },
  {
    Component: FeeScheduleDetails,
    path: `${ADMIN_ROUTES.BILLING_FEE_SCHEDULES}/:id`,
    name: 'Fee Schedule Details',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [BILLING.viewFeeSchedules]
  },
  {
    Component: NoFeeScheduleAccounts,
    path: ADMIN_ROUTES.BILLING_NO_FEE_SCHEDULES,
    name: 'No Fee Schedules',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [BILLING.viewFeeSchedules]
  },
  {
    Component: MultipleFeeScheduleAccounts,
    path: ADMIN_ROUTES.BILLING_MULTIPLE_FEE_SCHEDULES,
    name: 'Multiple Fee Schedules',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [BILLING.viewFeeSchedules]
  },
  {
    Component: BillingRunDetailsIndex,
    path: ADMIN_ROUTES.BILLING_RUN_DETAILS,
    name: 'Billing Run Details',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: ADMIN_ROUTES.BILLING
  },
  {
    Component: BillingRunDetailsIndex,
    path: `${ADMIN_ROUTES.BILLING_RUN_DETAILS}/:id`,
    name: 'Billing Run Details',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [BILLING.viewFeeSchedules],
    parentPath: ADMIN_ROUTES.BILLING_RUN_DETAILS
  },
  {
    Component: ClientFeeAssignments,
    path: ADMIN_ROUTES.BILLING_CLIENT_ASSIGNMENTS,
    name: 'Client Assignments',
    exact: true,
    forAdvisor: true
  },
  {
    Component: RunBilling,
    path: ADMIN_ROUTES.BILLING_RUN,
    name: 'Run Billing',
    exact: true,
    forAdvisor: true,
    backButton: true,
    policies: [BILLING.runBilling]
  },
  {
    Component: ReportTemplates,
    path: ADMIN_ROUTES.REPORTS_TEMPLATES,
    name: 'Report Templates',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS,
    policies: [REPORTS.runReports]
  },
  {
    Component: ReportTemplateConfigurations,
    path: ADMIN_ROUTES.REPORTS_TEMPLATES_RUNTIME_CONFIGURATIONS,
    name: 'Report Template Runtime Configurations',
    exact: true,
    forAdvisor: true,
    backButton: true,
    featureFlagKey: FEATURE_FLAG.REPORTS,
    policies: [REPORTS.runReports]
  },
  {
    Component: PreferencesView,
    path: '/preferences',
    name: 'User Preferences',
    exact: true,
    public: false,
    forAdvisor: false,
    backButton: true
  },
  {
    Component: DataHomeIndex,
    path: DATA_HOME_ROUTES.DATA_HOME,
    name: 'Data Home',
    exact: false,
    public: false,
    forAdvisor: true,
    backButton: true,
    parentPath: ADMIN_ROUTES.ADMIN,
    policies: [DATA_HOME.viewDataHome]
  },
  {
    path: DATA_HOME_ROUTES.DATA_SOURCE,
    name: 'Data Source',
    exact: true,
    public: false,
    forAdvisor: true,
    backButton: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME,
    policies: [DATA_HOME.viewDataHome]
  },
  {
    path: DATA_HOME_ROUTES.DATA_UPLOAD_FILE,
    name: 'Data Upload File',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME,
    policies: [DATA_HOME.viewDataHome]
  },
  {
    path: DATA_HOME_ROUTES.DATA_COMPLIANCE_REPORTS,
    name: 'Data Compliance Reports',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME,
    policies: [DATA_HOME.viewDataHome]
  },
  {
    path: DATA_HOME_ROUTES.DATA_QUERY,
    name: 'Data Query',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME,
    policies: [DATA_HOME.viewDataHome]
  },
  {
    path: DATA_HOME_ROUTES.DATA_HOME_JOBS,
    name: 'Jobs',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME
  },
  {
    path: DATA_HOME_ROUTES.DATA_HOME_JOB_DETAILS,
    name: 'Job Details',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME_JOBS
  },
  {
    path: DATA_HOME_ROUTES.DATA_HOME_JOB_RESULTS,
    name: 'Job Status / Results',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME_JOBS
  },
  {
    path: DATA_HOME_ROUTES.DATA_HOME_TARGETS,
    name: 'Ingestion Targets',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME
  },
  {
    path: DATA_HOME_ROUTES.DATA_HOME_TARGET_DETAILS,
    name: 'Ingestion Target Details',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME_TARGETS
  },
  {
    path: DATA_HOME_ROUTES.DATA_HOME_PUBLISH_PREVIEW,
    name: 'Publish Preview',
    exact: true,
    public: false,
    forAdvisor: true,
    backButton: false
  },
  {
    path: DATA_HOME_ROUTES.DATA_HOME_PUBLISH_RESULTS,
    name: 'Publish Status / Results',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: DATA_HOME_ROUTES.DATA_HOME_TARGETS
  },
  {
    path: '/denali-labs',
    name: 'Denali Labs',
    exact: true,
    Component: DenaliLabsIndex,
    public: false,
    forAdvisor: true,
    backButton: true,
    parentPath: '/'
  },
  {
    Component: Benchmarks,
    path: `${ADMIN_ROUTES.BENCHMARKS}`,
    name: 'Benchmarks',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: ADMIN_ROUTES.ADMIN,
    featureFlagKey: FEATURE_FLAG.BENCHMARKS_SETUP,
    policies: [BENCHMARKS.viewBenchmarks]
  },
  {
    Component: BlendedForm,
    path: ADMIN_ROUTES.BENCHMARKS_BLENDED_NEW,
    name: 'Blended Benchmark',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: ADMIN_ROUTES.BENCHMARKS,
    featureFlagKey: FEATURE_FLAG.BENCHMARKS_SETUP,
    policies: [BENCHMARKS.editBlends]
  },
  {
    Component: BlendedForm,
    path: `${ADMIN_ROUTES.BENCHMARKS_BLENDED}/:benchmarkId`,
    name: 'Blended Benchmark',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: ADMIN_ROUTES.BENCHMARKS,
    featureFlagKey: FEATURE_FLAG.BENCHMARKS_SETUP,
    policies: [BENCHMARKS.viewBenchmarks]
  },
  {
    Component: SourceInfo,
    path: `${ADMIN_ROUTES.BENCHMARKS_SOURCE}/:benchmarkId`,
    name: 'Benchmark Source',
    exact: true,
    forAdvisor: true,
    backButton: true,
    parentPath: ADMIN_ROUTES.BENCHMARKS,
    featureFlagKey: FEATURE_FLAG.BENCHMARKS_SETUP,
    policies: [BENCHMARKS.viewBenchmarks]
  },
  {
    Component: RebalancerIndex,
    path: '/rebalancer',
    name: 'Rebalancer',
    exact: false,
    public: false,
    backButton: false,
    forAdvisor: true,
    parentPath: '/'
    // featureFlagKey: FEATURE_FLAG.REBALANCER
  },
  {
    path: '/rebalancer/start',
    name: 'Start a Rebalancing Session',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/'
  },
  {
    path: '/rebalancer/start/client',
    name: 'Select a Client',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/rebalancer/start'
  },
  {
    path: '/rebalancer/start/workflow',
    name: 'Select a Workflow',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/rebalancer/start'
  },
  {
    path: '/rebalancer/analysis/rebalance',
    name: 'Rebalance Analysis',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/rebalancer'
  },
  {
    path: '/rebalancer/analysis/raiseCash',
    name: 'Raise Cash Analysis',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/rebalancer'
  },
  {
    path: '/rebalancer/results/:rebalancingRunId',
    name: 'Rebalance Results',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/rebalancer'
  },
  {
    path: '/rebalancer/model/:targetModelId',
    name: 'Target Model Details',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/rebalancer'
  },
  {
    path: '/conversations',
    Component: ConversationsIndex,
    name: 'Conversations',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: false,
    parentPath: '/'
  },
  {
    path: '/conversations/c/:clientId',
    name: 'Conversations',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: false,
    parentPath: '/conversations'
  },
  {
    path: '/conversations/c/:clientId/new',
    name: 'New Conversation',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: false,
    parentPath: '/conversations'
  },
  {
    path: '/conversations/c/:clientId/t/:conversationKey',
    name: 'Conversations',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: false,
    parentPath: '/conversations'
  },
  {
    Component: FirmReporting,
    path: '/firm-reporting/r/:reportId',
    name: 'Firm Reporting',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/'
  },
  {
    path: '/data-home/out/r/:reportId',
    name: 'Firm Reporting',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/data-home/?tab=dataOut'
  },
  {
    path: '/data-home/out/s/:reportId',
    name: 'Firm Reporting',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: '/data-home/?tab=dataOut'
  },
  {
    path: ADMIN_ROUTES.NEW_MANAGER,
    Component: NewManager,
    name: 'Create Manager',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: `${ADMIN_ROUTES.ACCOUNTS}?tab=managers`
  },
  {
    path: `${ADMIN_ROUTES.MANAGER_DETAILS}/:managerId`,
    Component: ManagerDetailsIndex,
    name: 'Manager Details',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: `${ADMIN_ROUTES.ACCOUNTS}?tab=managers`
  },
  {
    path: ADMIN_ROUTES.STATUS_REPORTS,
    Component: StatusReportsIndex,
    name: 'Status Reports',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.ADMIN
  },
  {
    path: ADMIN_ROUTES.NEW_STATUS_REPORT_TEMPLATE,
    Component: NewStatusTemplate,
    name: 'New Status Report Template',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.STATUS_REPORTS
  },
  {
    path: `${ADMIN_ROUTES.STATUS_TEMPLATE_DETAILS}/:templateId`,
    Component: StatusTemplateDetails,
    name: 'Status Report Template',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.STATUS_REPORTS
  },
  {
    path: ADMIN_ROUTES.MODEL_ADMIN,
    Component: ModelAdminIndex,
    name: 'Model Admin',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.ADMIN
  },
  {
    path: `${ADMIN_ROUTES.TARGET_MODEL_DETAILS}/new`,
    Component: NewTargetModel,
    name: 'Create Target Model',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.TARGET_MODELS
  },
  {
    path: `${ADMIN_ROUTES.TARGET_MODEL_DETAILS}/:targetModelId`,
    Component: TargetModelDetails,
    name: 'Target Model Details',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.TARGET_MODELS
  },
  {
    path: `${ADMIN_ROUTES.COMPONENT_MODEL_DETAILS}/new`,
    Component: NewComponentModel,
    name: 'Create Component Model',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.COMPONENT_MODELS
  },
  {
    path: `${ADMIN_ROUTES.COMPONENT_MODEL_DETAILS}/:componentModelId`,
    Component: ComponentModelDetails,
    name: 'Component Model Details',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.COMPONENT_MODELS
  },
  {
    path: `${ADMIN_ROUTES.MEDIA}`,
    Component: MediaIndex,
    name: 'Media Management',
    exact: true,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.ADMIN
  },
  {
    Component: CustomIconsView,
    path: '/custom-icons',
    name: 'Custom Icons',
    exact: true,
    public: false,
    backButton: false,
    forAdvisor: true,
    parentPath: '/'
  },
  {
    Component: VaultAdmin,
    path: `${ADMIN_ROUTES.VAULT}`,
    name: 'Vault',
    exact: false,
    public: false,
    backButton: false,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.ADMIN
  },
  {
    Component: DenaliIndex,
    path: `${ADMIN_ROUTES.DENALI.HOME}`,
    name: 'Denali',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.ADMIN
  },
  {
    Component: IntegratorHome,
    path: `${ADMIN_ROUTES.DENALI.INTEGRATOR.HOME}`,
    name: 'Denali Integrator',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.HOME
  },
  {
    Component: IntegratorHome,
    path: `${ADMIN_ROUTES.DENALI.INTEGRATOR.DOMAINS}`,
    name: 'Denali Integrator Domains',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.INTEGRATOR.HOME
  },
  {
    Component: IntegratorHome,
    path: `${ADMIN_ROUTES.DENALI.INTEGRATOR.SOURCEDETAILS}`,
    name: 'Denali Integrator Source',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.INTEGRATOR.CONNECTIONS
  },
  {
    Component: IntegratorHome,
    path: `${ADMIN_ROUTES.DENALI.INTEGRATOR.JOBS}`,
    name: 'Denali Integrator Jobs',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.INTEGRATOR.HOME
  },
  {
    Component: IntegratorHome,
    path: `${ADMIN_ROUTES.DENALI.INTEGRATOR.JOBDETAILS}`,
    name: 'Denali Integrator Job',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.INTEGRATOR.JOBS
  },
  {
    Component: IntegratorHome,
    path: `${ADMIN_ROUTES.DENALI.INTEGRATOR.BATCHES}`,
    name: 'Denali Integrator Batches',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.INTEGRATOR.HOME
  },
  {
    Component: IntegratorHome,
    path: `${ADMIN_ROUTES.DENALI.INTEGRATOR.BATCHDETAILS}`,
    name: 'Denali Integrator Batch',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.INTEGRATOR.BATCHES
  },
  {
    Component: EditPipeline,
    path: `${ADMIN_ROUTES.DENALI.ANALYSIS.NEW_PIPELINE}`,
    name: 'Create Data Pipeline',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.ANALYSIS.PIPELINES
  },
  {
    Component: EditPipeline,
    path: `${ADMIN_ROUTES.DENALI.ANALYSIS.EDIT_PIPELINE}`,
    name: 'Edit Data Pipeline',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.ANALYSIS.PIPELINES
  },
  {
    Component: EventLogsHome,
    path: `${ADMIN_ROUTES.DENALI.CATALOG.EVENT_LOGS}`,
    name: 'Event Logs',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.CATALOG.HOME
  },
  {
    Component: DataSourceList,
    path: `${ADMIN_ROUTES.DENALI.CATALOG.DATA_SOURCES}`,
    name: 'Data Sources',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.CATALOG.HOME
  },
  {
    Component: DataSourceDetails,
    path: `${ADMIN_ROUTES.DENALI.CATALOG.DATA_SOURCE_DETAILS}`,
    name: 'Data Source',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.CATALOG.DATA_SOURCES
  },
  {
    Component: CatalogHome,
    path: `${ADMIN_ROUTES.DENALI.CATALOG.HOME}`,
    name: 'Denali Data Catalog',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.HOME
  },
  {
    Component: PipelinesHome,
    path: `${ADMIN_ROUTES.DENALI.ANALYSIS.PIPELINES}`,
    name: 'Denali Data Pipelines',
    exact: false,
    public: false,
    backButton: true,
    forAdvisor: true,
    parentPath: ADMIN_ROUTES.DENALI.HOME
  }
]

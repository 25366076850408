import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'
import papa from 'papaparse'
import { useHistory } from 'react-router-dom'
import SydSelect from '../../../../../commonDesign/SydSelect'
import SydLabel, { hookFormFieldErrorAdapter } from '../../../../../commonDesign/SydLabel'
import DragAndDropInputArea from '../../../../../molecules/DragAndDropInputArea'
import SydButton from '../../../../../commonDesign/Button'
import SydModal from '../../../../../commonDesign/SydModal'
import SydModalActions from '../../../../../commonDesign/SydModal/SydModalActions'
import { pluralize } from '../../../../../../utils'
import Text from '../../../../../atoms/Text'
import { ADMIN_ROUTES, TEXT_VARIANTS, USER_TYPE } from '../../../../../../constants'
import { useCreateUserBatchMutation } from '../../../../../../api/users'
import { DataPreviewTable } from '../DataPreviewTable'
import { useGetRowMissingFields } from '../DataPreviewTable/hooks'
import { DropAreaDisplay } from './DropAreaDisplay'
import { CreateBatchInstructions } from './CreateBatchInstructions'

const batchTypes = [
  { label: 'Bulk Create Users', value: 'bulk-create-users' },
  { label: 'Bulk Invite Users', value: 'bulk-invite-users' }
]

const requiredColumnsMap = {
  'bulk-create-users': [{ personId: { userTypeId: USER_TYPE.WEALTH_OWNER } }, 'roleId', 'clientId', 'firstName', 'lastName', 'email', 'userTypeId', 'allAccountAccess']
}
const usedColumnsMap = {
  'bulk-create-users': ['personId', 'roleId', 'clientId', 'firstName', 'lastName', 'email', 'userTypeId', 'allAccountAccess']
}

const useStyles = makeStyles({
  container: {
    padding: '1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 50px)',
    overflow: 'hidden',
    gap: '1rem'
  },
  formContainer: {
    padding: '0 1rem'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  },
  dropzoneText: {
    textAlign: 'center'
  },
  fileInputContainer: {
    minHeight: '3rem',
    display: 'flex',
    flexDirection: 'column'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    padding: '0 1rem'
  }
})

export const CreateUserTaskBatchView = () => {
  const classes = useStyles()
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const [data, setData] = useState(null)
  const [editing, setEditing] = useState(false)
  const [confirmIncompleteCount, setConfirmIncompleteCount] = useState(false)

  const form = useForm({
    defaultValues: {
      batchType: urlParams.get('batchType') ?? 'bulk-create-users'
    }
  })
  const {
    watch,
    setError,
    setValue,
    control
  } = form

  const batchType = watch('batchType')

  const validator = useCallback(async (file) => {
    if (file.type !== 'text/csv') {
      return 'Invalid file type. Please upload a CSV file.'
    }

    await new Promise((resolve, reject) => {
      papa.parse(file, {
        skipEmptyLines: true,
        header: true,
        dynamicTyping: true,
        complete: ({ data, errors, meta }) => {
          setError('file', null)
          if (errors.length) {
            setData(null)
            setValue('file', null, { shouldDirty: true })
            setError('file', {
              message: 'Issue with the file you uploaded.  Make sure it matches the requirements to the right'
            })
            return reject(new Error('Issue with the file you uploaded.  Make sure it matches the requirements to the right'))
          }
          setValue('file', file, { shouldDirty: true })
          const columns = [...new Set([
            ...(meta.fields ?? []),
            ...(usedColumnsMap[batchType] ?? [])
          ])]

          setData({
            name: file.name,
            columns,
            data: data.map(r => ({
              ...r,
              ...(batchType === 'bulk-create-users' && { allAccountAccess: !!r.allAccountAccess })
            }))
          })
          resolve(null)
        }
      })
    })

    return null
  }, [batchType, setError, setValue])

  const requiredColumns = useMemo(() => requiredColumnsMap[batchType], [batchType])
  const usedColumns = useMemo(() => usedColumnsMap[batchType], [batchType])
  const getRowMissingFields = useGetRowMissingFields(requiredColumns)
  const incompleteRowCount = useMemo(() => data?.data.filter(row => getRowMissingFields(row).length > 0).length, [data?.data, getRowMissingFields])
  const completeRows = useMemo(() => data?.data.filter(row => !getRowMissingFields(row).length), [data?.data, getRowMissingFields])

  const { createBatch, isLoading } = useCreateUserBatchMutation()
  const onSubmit = useCallback(async (values) => {
    if (!confirmIncompleteCount) {
      if (incompleteRowCount) {
        return setConfirmIncompleteCount(incompleteRowCount)
      }
    }

    const userBatch = await createBatch({
      batchType: values.batchType,
      data: completeRows
    })

    history.push(`${ADMIN_ROUTES.USER_BATCHES}/batch/${userBatch.userBatchId}`)
  }, [history, completeRows, confirmIncompleteCount, incompleteRowCount, createBatch])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className={classes.container}>
        <Grid container spacing={3} className={classes.formContainer}>
          <Grid item xs={12} md={4} className={classes.inputContainer}>
            <Controller
              name='batchType'
              control={control}
              render={({ field }) => (
                <SydLabel label='Batch Type to run' required>
                  <SydSelect
                    size='sm'
                    options={batchTypes}
                    {...field}
                  />
                </SydLabel>
              )}
            />
            <Controller
              name='file'
              control={control}
              render={({ field, fieldState }) => (
                <div className={classes.fileInputContainer}>
                  <SydLabel label='CSV to import' required error={hookFormFieldErrorAdapter(fieldState)}>
                    <DragAndDropInputArea
                      maxFiles={1}
                      validator={validator}
                      acceptedFileTypes='text/csv'
                      onDropAccepted={(files) => (field.value = files[0])}
                      containerStyle={{ padding: 0, justifyContent: 'stretch', alignItems: 'stretch' }}
                    >
                      {({ openUploadFileWindow }) => (
                        <DropAreaDisplay
                          openFileUploadWindow={openUploadFileWindow}
                          file={watch('file')}
                        />
                      )}
                    </DragAndDropInputArea>
                  </SydLabel>
                </div>
              )}
            />
          </Grid>
          <Grid item md={8}>
            <CreateBatchInstructions batchType={watch('batchType')} />
          </Grid>
          <Grid item sm={12}>
            <hr />
          </Grid>
        </Grid>

        {watch('file') && data && (
          <>
            <DataPreviewTable
              title={batchTypes.find(x => x.value === watch('batchType'))?.label + ' Preview'}
              data={data}
              onChange={(newData) => setData(newData)}
              usedColumns={usedColumns}
              requiredColumns={requiredColumns}
              editing={editing}
              onSetEditing={setEditing}
            />

            <div className={classes.actionsContainer}>
              <SydButton
                disabled={editing}
                type='submit'
                variant='primary'
                processing={isLoading}
              >
                {isLoading ? 'Processing...' : 'Start Batch'}
              </SydButton>
            </div>
          </>
        )}
      </div>

      <SydModal
        open={!!confirmIncompleteCount}
        onClose={() => setConfirmIncompleteCount(null)}
        title='Are you sure?'
        subtitle={`${confirmIncompleteCount} ${pluralize(confirmIncompleteCount, 'row')} are missing required fields.`}
      >
        <Text text='Are you sure you want to continue?' variant={TEXT_VARIANTS.body1} customFontSize='14px' />
        <Text text='Those rows will NOT be included in the batch and will be skipped.' variant={TEXT_VARIANTS.body1} customFontSize='14px' />

        <SydModalActions style={{ marginTop: '2rem' }}>
          <SydButton
            variant='secondary'
            onClick={() => setConfirmIncompleteCount(null)}
          >
            Cancel
          </SydButton>
          <SydButton
            variant='primary'
            type='submit'
            processing={isLoading}
          >
            {isLoading ? 'Processing...'
              : `Continue with ${data?.data?.length - confirmIncompleteCount} complete ${pluralize(data?.data?.length - confirmIncompleteCount, 'row')}`}
          </SydButton>
        </SydModalActions>
      </SydModal>
    </form>
  )
}

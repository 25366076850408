import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import SydSelect from '../../../../../../commonDesign/SydSelect'
import SydModal from '../../../../../../commonDesign/SydModal'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import SydButton from '../../../../../../commonDesign/Button'
import SydLabel from '../../../../../../commonDesign/SydLabel'
import { useSearchRoles } from '../../../../../../../api/users'
import { USER_TYPE } from '../../../../../../../constants'

export const RoleIdFillModal = ({ open, onClose, onSubmit: _onSubmit }) => {
  const { data: roles } = useSearchRoles({})

  const form = useForm({
    defaultValues: {
      roleId: ''
    }
  })

  const onSubmit = (values) => {
    _onSubmit?.(values.roleId)
    onClose?.()
    form.reset()
  }

  const roleId = form.watch('roleId')
  const description = useMemo(() => {
    if (!roleId?.toString()?.length) return ''
    const selectedRole = roles?.find(role => role.roleId.toString() === roleId.toString())
    if (selectedRole.assignableToUserType === USER_TYPE.WEALTH_OWNER) {
      return 'Will only fill Wealth Owner user types'
    }
    if (selectedRole.assignableToUserType === USER_TYPE.ADVISOR) {
      return 'Will only fill Advisor user types'
    }
  }, [roleId, roles])

  return (
    <SydModal
      open={open}
      disablePortal={false}
      onClose={onClose}
      title='Fill Empty Roles'
      subtitle='Choose the role to fill any empty roles with below.  It will only fill values where the user type is set and matchs the selected role.'
    >
      <form {...form} onSubmit={form.handleSubmit(onSubmit)}>
        <Controller
          name='roleId'
          control={form.control}
          rules={{ required: 'Role is required' }}
          render={({ field, fieldState }) => (
            <SydLabel label='Role' required error={fieldState.error?.message} description={description}>
              <SydSelect
                style={{ marginBottom: '5px' }}
                size='sm'
                {...field}
                options={roles.map(role => ({ label: `${role.name} ${role.assignableToUserType === USER_TYPE.WEALTH_OWNER ? '(Wealth Owner)' : role.assignableToUserType === USER_TYPE.ADVISOR ? '(Advisor)' : ''}`, value: role.roleId }))}
              />
            </SydLabel>
          )}
        />
        <SydModalActions style={{ marginTop: '2rem' }}>
          <SydButton onClick={onClose} variant='secondary'>Cancel</SydButton>
          <SydButton onClick={form.handleSubmit(onSubmit)} variant='primary'>Fill Empty Roles</SydButton>
        </SydModalActions>
      </form>
    </SydModal>
  )
}

RoleIdFillModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

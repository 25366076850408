import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import SydInput from '../../../../../../commonDesign/SydInput'
import { RoleSelectCell } from './RoleSelectCell'
import { AllAccountAccessCell } from './AllAccountAccessCell'
import { UserTypeSelectCell } from './UserTypeSelectCell'
import { PersonIdCell } from './PersonIdCell'

export const cellAccessorMap = {
  roleId: RoleSelectCell,
  allAccountAccess: AllAccountAccessCell,
  userTypeId: UserTypeSelectCell,
  personId: PersonIdCell
}

const useStyles = makeStyles((theme) => ({
  disabledDisplay: {
    padding: '4px 6px',
    lineHeight: '18px',
    minHeight: '18px',
    margin: 0,
    fontSize: theme.typography.fontSizes.md
  }
}))

export const DataPreviewTableCell = ({ id, accessorKey, disabled, row, onChange }) => {
  const classes = useStyles()
  const CellComponent = cellAccessorMap[accessorKey]
  if (!CellComponent) {
    if (disabled) {
      return (
        <p className={classes.disabledDisplay}>
          {row[accessorKey]}
        </p>
      )
    }
    return (
      <SydInput
        minWidth='0px'
        type='text'
        size='xs'
        id={id}
        value={row[accessorKey]}
        onChange={e => onChange(accessorKey, e.target.value)}
      />
    )
  }

  return (
    <CellComponent
      id={id}
      accessorKey={accessorKey}
      row={row}
      disabled={disabled}
      onChange={e => onChange(accessorKey, e.target.value)}
      classes={classes}
    />
  )
}

DataPreviewTableCell.propTypes = {
  id: PropTypes.string,
  accessorKey: PropTypes.string,
  disabled: PropTypes.bool,
  row: PropTypes.object,
  onChange: PropTypes.func
}

import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSearchRoles } from '../../../../../../../api/users'

export const RoleSelectCell = ({ id, row, disabled, onChange, classes }) => {
  const { data: roles, isLoading: rolesLoading, isSuccess } = useSearchRoles({})

  const filteredRoles = useMemo(() => roles?.filter(role => role.assignableToUserType?.toString() === row?.userTypeId?.toString()) ?? [], [roles, row])

  useEffect(() => {
    if (!row.roleId?.toString().length || !isSuccess) return
    if (!row?.userTypeId?.toString()?.length || !filteredRoles?.find(role => role.roleId.toString() === row.roleId?.toString())) {
      return onChange({ target: { value: '' } })
    }
  }, [filteredRoles, isSuccess, onChange, row.roleId, row?.userTypeId])

  const selectedRole = useMemo(() => (
    filteredRoles.find(role => role.roleId.toString() === row.roleId?.toString())
  ), [filteredRoles, row.roleId])

  if (rolesLoading) {
    return <p className={classes.disabledDisplay}>Loading...</p>
  }

  if (disabled) {
    return (
      <p className={classes.disabledDisplay}>
        {selectedRole ? selectedRole.name : 'No role assigned'}
      </p>
    )
  }

  if (!row?.userTypeId?.toString()?.length) {
    return <input value='Select a User Type' disabled />
  }

  return (
    <>
      <select id={id} onChange={onChange}>
        <option value=''>No role assigned</option>
        {filteredRoles.map(role => (
          <option
            key={role.roleId}
            value={role.roleId}
            selected={role.roleId.toString() === row.roleId?.toString()}
          >
            {role.name}
          </option>
        ))}
      </select>
    </>
  )
}

RoleSelectCell.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  row: PropTypes.object,
  onChange: PropTypes.func,
  classes: PropTypes.object
}

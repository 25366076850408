import React from 'react'
import { useParams } from 'react-router-dom'
import { CreateUserTaskBatchView } from './CreateUserBatch'
import { UserBatchDetails } from './UserBatchDetails'

export const UserBatch = (props) => {
  const { userBatchId } = useParams()

  if (userBatchId === 'new') {
    return <CreateUserTaskBatchView />
  }

  return (
    <UserBatchDetails userBatchId={userBatchId} />
  )
}

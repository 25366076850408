import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Tooltip from '../../../../../atoms/Tooltip'
import Icon from '../../../../../atoms/Icon'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SydButton from '../../../../../commonDesign/Button'
import { useGetRowMissingFields } from './hooks'
import { DataPreviewTableCell } from './cells'
import { DataPreviewTableHeader } from './headers'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: '2px 1rem',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    gap: '1rem'
  },
  inputTable: {
    borderCollapse: 'collapse',
    width: '100%',
    '& tbody': {
      overflow: 'scroll'
    },
    '& tr': {
      '&.error': {
        '& td': {
          backgroundColor: theme.palette.error.A50
        }
      },
      '&.hidden': {
        display: 'none'
      }
    },
    '& td': {
      border: `1px solid ${theme.palette.gray.main}`,
      opacity: 0.6,
      minWidth: 'fit-content',
      '&.required': {
        opacity: 1,
        '& input': {
          color: theme.typography.color,
          opacity: 1,
          fontWeight: '400',
          fontFamily: theme.typography.families.bold
        },
        '& select': {
          color: theme.typography.color,
          opacity: 1,
          fontWeight: '400',
          fontFamily: theme.typography.families.bold
        }
      }
    },
    '& input': {
      width: '100%',
      border: 'none',
      borderRadius: '0px',
      background: 'transparent',
      padding: '4px 8px',
      fontSize: '12px'
    },
    '& select': {
      width: '100%',
      border: 'none',
      borderRadius: '0px',
      background: 'transparent',
      padding: '4px 8px',
      fontSize: '12px',
      height: '32px'
    }
  },
  inputTableWrapper: {
    height: '100%',
    overflow: 'auto'
  },
  flexContainer: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: theme.typography.fontSizes.xl
  },
  actionMenu: {
    '& button': {
      padding: 0
    }
  }
}))
export const DataPreviewTable = ({ title, data, onChange, onEditChange, usedColumns, requiredColumns, editing, onSetEditing }) => {
  const classes = useStyles()
  const [hideUnusedColumns, setHideUnusedColumns] = useState(true)
  const [showOnlyErrored, setShowOnlyErrored] = useState(false)
  const rows = useRef({})
  const [editingData, setEditingData] = useState(data?.data)

  const setRow = (key, node) => {
    if (node && key !== undefined) rows.current[key] = node
  }

  const toggleEdit = useCallback(() => {
    onSetEditing(!editing)
    onEditChange?.(!editing)
    if (editing && onChange) {
      onChange({ ...data, data: editingData })
    }
  }, [onSetEditing, editing, onEditChange, onChange, data, editingData])

  const cancelEdit = useCallback(() => {
    onSetEditing(false)
    onEditChange?.(false)
    setEditingData(data.data)
  }, [onSetEditing, onEditChange, data])

  const handleFillSubmit = useCallback((accessorKey, value) => {
    setEditingData((prev) => {
      return prev.map((row) => {
        if (!row[accessorKey]?.toString()?.length) {
          return { ...row, [accessorKey]: value }
        }
        return row
      })
    })
  }, [])

  const getRowMissingFields = useGetRowMissingFields(requiredColumns)
  const emptyAccessors = useMemo(() => data.columns.filter(cn => editingData.some(row => !row[cn]?.toString()?.length)), [data.columns, editingData])

  useEffect(() => {
    setEditingData(data?.data)
  }, [data])

  if (!editingData?.length) {
    return null
  }

  return (
    <div className={classes.tableContainer}>
      <div className={classes.tableHeader}>
        <div className={classes.flexContainer}>
          <span>{title}</span>
          <SydButton
            size='xs'
            icon={hideUnusedColumns ? 'unfoldMore' : 'unfoldLess'}
            onClick={() => setHideUnusedColumns(!hideUnusedColumns)}
          >
            {hideUnusedColumns ? 'Show Ignored Columns' : 'Hide Ignored Columns'}
          </SydButton>
          <SydButton
            size='xs'
            icon='filters'
            onClick={() => setShowOnlyErrored(!showOnlyErrored)}
          >
            {showOnlyErrored ? 'Show All Rows' : 'Show only Rows with errors'}
          </SydButton>
        </div>
        <div>
          {!editing ? (
            <FadeIn key='not-editing' className={classes.flexContainer}>
              <SydButton
                size='xs'
                icon='edit'
                onClick={toggleEdit}
              >
                Edit
              </SydButton>
            </FadeIn>
          ) : (
            <FadeIn key='editing' className={classes.flexContainer}>
              <SydButton
                size='xs'
                variant='secondary'
                icon='close'
                onClick={cancelEdit}
              >
                Cancel
              </SydButton>
              <SydButton
                size='xs'
                variant='primary'
                icon='check'
                onClick={toggleEdit}
              >
                Apply Changes
              </SydButton>
            </FadeIn>
          )}

        </div>
      </div>
      <div className={classes.inputTableWrapper}>
        <table className={classes.inputTable}>
          <DataPreviewTableHeader
            columns={data.columns.filter(cn => !hideUnusedColumns || usedColumns.includes(cn))}
            editing={editing}
            emptyAccessors={emptyAccessors}
            usedColumns={usedColumns}
            onFillSubmit={handleFillSubmit}
          />

          <tbody>
            {editingData?.map((d, i) => {
              const rowMissingFields = getRowMissingFields(d)

              return (
                <tr
                  ref={(node) => setRow(i, node)}
                  key={`row${i}`}
                  className={clsx({
                    error: rowMissingFields?.length,
                    hidden: showOnlyErrored && !rowMissingFields?.length
                  })}
                >
                  <td className={classes.rowHeader}>{i + 1}</td>
                  <td>
                    {
                      rowMissingFields.length ? (
                        <Tooltip
                          key={`status-${i}`}
                          title={`
                          Missing some required fields:
                          ${rowMissingFields.flatMap(x => typeof x === 'object' ? Object.keys(x) : x)?.join(', ')}
                        `}
                          placement='top'
                        >
                          <div><Icon name='warning' color='orange' /></div>
                        </Tooltip>
                      ) : (
                        <Icon name='check' color='green' />
                      )
                    }
                  </td>
                  {data.columns.filter(cn => !hideUnusedColumns || usedColumns.includes(cn)).map(cn => (
                    <td
                      key={`row${i}_${cn}`}
                      className={usedColumns.includes(cn) ? 'required' : ''}
                    >
                      <DataPreviewTableCell
                        id={`tbl_row_${i}_col_${cn}`}
                        accessorKey={cn}
                        row={editingData[i]}
                        onChange={(key, val) => {
                          setEditingData((prev) => {
                            const newRow = { ...prev[i], [key]: val }
                            return prev.map((r, index) => (index === i ? newRow : r))
                          })
                        }}
                        disabled={!editing}
                      />
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

DataPreviewTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape({
    columns: PropTypes.array,
    data: PropTypes.array
  }),
  onChange: PropTypes.func,
  onEditChange: PropTypes.func,
  usedColumns: PropTypes.array,
  requiredColumns: PropTypes.array,
  editing: PropTypes.bool,
  onSetEditing: PropTypes.func
}

/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useGetUserBatchTasks } from '../../../../../../api/userBatches'
import { useFormattingContext } from '../../../../../organisms/FormattingProvider/FormattingContext'
import OperationalTable, { useOperationalTable } from '../../../../../organisms/OperationalTable'
import Icon from '../../../../../atoms/Icon'
import Tooltip from '../../../../../atoms/Tooltip'
import { ADMIN_ROUTES, USER_TYPE } from '../../../../../../constants'
import SydLinkButton from '../../../../../commonDesign/LinkButton'

const useStyles = makeStyles((theme) => ({
  batchTable: {
    marginTop: '10px',
    '& .__status': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '4px'
    },
    '& .stateCell': {
      border: '1px solid',
      borderRadius: '6px',
      display: 'inline-block',
      padding: '2px 6px',
      lineHeight: '20px',
      height: '24px',
      verticalAlign: 'baseline',
      '&.errored': {
        backgroundColor: theme.palette.error.A50,
        color: theme.palette.error.main
      },
      '&.pending': {
        backgroundColor: theme.palette.gray.main,
        color: theme.palette.gray.contrastText
      },
      '&.completed': {
        backgroundColor: theme.palette.success.A50,
        color: theme.palette.success.main
      }
    }
  }
}))

const NumberCell = ({ value }) => {
  const { formatter } = useFormattingContext()
  return <div>{formatter(value, '0,0')}</div>
}

const MetadataCell = ({ value, row }) => {
  return (
    <Tooltip
      title={(
        <pre>{JSON.stringify(value, null, 2)}</pre>
      )}
      placement='top'
    >
      {row.original.taskType === 'bulk-create-users/create-user' ? (
        <div>{row.original.metadata.firstName} {row.original.metadata.lastName} ({row.original.metadata.email})</div>
      ) : (
        <div><Icon name='threeDots' /></div>
      )}
    </Tooltip>
  )
}

const StateCell = ({ value, row }) => {
  if (row.original.isError) {
    return (
      <Tooltip
        title={(
          <pre>{JSON.stringify(row.original?.errorData, null, 2)}</pre>
        )}
        placement='top'
      >
        <div className='stateCell errored'>Error</div>
      </Tooltip>
    )
  }
  if (row.original.isComplete) {
    return (
      <Tooltip
        title={(
          <pre>{JSON.stringify(row.original?.resultData, null, 2)}</pre>
        )}
        placement='top'
      >
        <div className='stateCell completed'>Completed</div>
      </Tooltip>
    )
  }
  return <div className='stateCell pending'>Pending...</div>
}

const CreateUserActionCell = ({ row }) => {
  const { externalUserId } = row.original.resultData?.user ?? {}
  const baseUrl = row.original.metadata?.userTypeId === USER_TYPE.WEALTH_OWNER ? ADMIN_ROUTES.WEALTH_OWNERS
    : row.original.metadata?.userTypeId === USER_TYPE.ADVISOR ? ADMIN_ROUTES.ADVISORS : null
  if (!row.original.isComplete || !externalUserId || !baseUrl) return ''

  return (
    <>
      <SydLinkButton
        size='sm'
        variant='outline'
        icon='user'
        to={`${baseUrl}/${externalUserId}`}
      >
        View Created {row.original.metadata?.userTypeId === USER_TYPE.WEALTH_OWNER ? 'Wealth Owner' : 'Advisor'}
      </SydLinkButton>
    </>
  )
}
const defaultSort = [{ id: 'taskPriority', desc: true }]
const defaultPageSize = 50

function BatchTaskListTable ({ userBatchId, batchType }) {
  const classes = useStyles()

  const columns = useMemo(() => ([
    { Header: 'Id', accessor: 'userBatchTaskId', type: 'number', Cell: NumberCell },
    { Header: 'Ordinal', id: 'taskPriority', accessor: 'taskPriority', type: 'number', Cell: NumberCell },
    { Header: 'Task Type', id: 'taskType', accessor: 'taskType' },
    { Header: 'Status', accessor: 'isError', Cell: StateCell },
    { Header: 'Metadata', id: 'metadata', accessor: 'metadata', type: 'string', Cell: MetadataCell },
    ...(batchType === 'bulk-create-users' ? [
      { Header: 'Actions', id: 'actions', accessor: 'userBatchTaskId', Cell: CreateUserActionCell }
    ] : [])
  ]), [batchType])

  const {
    pageSize,
    pageIndex,
    sort
  } = useOperationalTable({
    defaultPageSize,
    defaultSort
  })

  const { data, isFetching } = useGetUserBatchTasks({
    filters: {
      userBatchId
    },
    take: pageSize,
    skip: pageIndex * pageSize,
    sort: sort.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))
  })

  return (
    <OperationalTable.Wrapper className={classes.batchTable}>
      <OperationalTable
        data={data || []}
        variant='v2'
        loading={isFetching}
        defaultPageSize={defaultPageSize}
        columns={columns}
        defaultSort={defaultSort}
        mode='server'
      />
    </OperationalTable.Wrapper>
  )
}

BatchTaskListTable.propTypes = {
  userBatchId: PropTypes.number,
  batchType: PropTypes.string
}

export default BatchTaskListTable

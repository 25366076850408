import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMyUserProfile } from '../../api/users'
import config from '../../config'

const pendo = global.pendo ?? {
  initialize () {}
}

const fullStory = global.FS ?? {
  identify () {}
}

const fullName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  if (firstName) {
    return firstName
  }

  return lastName
}

const AnalyticsProvider = ({ children, currentUser }) => {
  const [pendoInit, setPendoInit] = useState(false)
  const [fullStoryInit, setFullStoryInit] = useState(false)

  const { data: profile, isLoading } = useMyUserProfile()

  useEffect(() => {
    if (pendoInit) return
    if (!currentUser) return
    if (isLoading) return

    try {
      pendo.initialize({
        visitor: {
          id: currentUser.userId,
          email: currentUser.email,
          full_name: fullName(currentUser.firstName, currentUser.lastName),
          role: currentUser.userType,
          firmId: currentUser.firmId,
          internalId: currentUser.internalId,
          status: currentUser.status,
          assignedRoleId: profile.roleId,
          assignedRoleName: profile.roleName
        },
        account: {
          id: currentUser.firmId
        }
      })
      setPendoInit(true)
      console.log('pendo initialized')
    } catch (err) {
      console.warn('unable to initialize pendo:', err.toString())
    }
  },
  [currentUser, pendoInit, setPendoInit, isLoading, profile])

  useEffect(() => {
    if (fullStoryInit) return
    if (!currentUser) return
    if (isLoading) return

    try {
      fullStory.identify(currentUser.email, {
        displayName: fullName(currentUser.firstName, currentUser.lastName),
        email: currentUser.email,
        productName_str: 'Summit',
        firmName_str: `SWS ${config.analytics.env} ${currentUser.firmId}`,
        firmId_int: `sws-${currentUser.firmId}`,
        loginLevel_str: currentUser.userType
      })
      setFullStoryInit(true)
      console.log('fullstory initialized')
    } catch (err) {
      console.warn('unable to initialize fullstory:', err.toString())
    }
  },
  [currentUser, fullStoryInit, setFullStoryInit, isLoading, profile])

  return children
}

AnalyticsProvider.propTypes = {
  children: PropTypes.node,
  currentUser: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userType: PropTypes.string,
    firmId: PropTypes.number,
    internalId: PropTypes.number,
    status: PropTypes.string
  })
}

export default AnalyticsProvider
